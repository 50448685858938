<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="onComplete"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <DatePicker
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            label="日付"
            :disabled="edit"
            :rules="[Rules.Required]"
        /></v-col>
        <v-col cols="12" class="dialog-padding">
          <v-autocomplete
            v-model="shozoku"
            :items="shozokus"
            outlined
            :rules="[Rules.Required]"
            label="所属"
            @input="shozokuSelect"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
          <v-autocomplete
            v-model="shift"
            :items="shifts"
            outlined
            label="シフト"
            @input="shiftSelect"
            item-text="name"
            item-value="code"
            chips
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="data.stampTime"
            label="打刻時間:"
            filled
            maxlength="50"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.start"
            label="勤務開始"
            prepend-inner-icon="mdi-clock-start"
            outlined
            :rules="[Rules.Required]"
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.end"
            label="勤務終了"
            prepend-inner-icon="mdi-clock-end"
            clearable
            outlined
            :rules="[Rules.Required(data.end, true)]"
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.breaktime"
            label="休憩"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.work"
            label="実働"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.over"
            label="時間外残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.inover"
            label="時間内残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.midnightover"
            label="深夜残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.midnight"
            label="深夜時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.lateTime"
            label="遅刻時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.leaveTime"
            label="早退時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="data.amount"
            label="単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="data.reserveAmount1"
            label="応援時給単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            :disabled="selectsOption !== SelectsOption.ASSIST"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model="data.biko"
            label="備考"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding"></v-col>
        <v-col class="dialog-padding"></v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectWork"
            row
            label="出勤種別:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="通常" :value="SelectsWork.Default"></v-radio>
            <v-radio label="休日" :value="SelectsWork.Break"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectDayCount"
            row
            label="出勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="1" :value="SelectsDayCount.Default"></v-radio>
            <v-radio label="2" :value="SelectsDayCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectsOption"
            row
            label="応援・その他:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsOption.NONE"></v-radio>
            <v-radio label="応援" :value="SelectsOption.ASSIST"></v-radio>
            <v-radio label="その他" :value="SelectsOption.OTHER"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectAbsence"
            row
            label="欠勤:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAbsence.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAbsence.Absence"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <p>有給設定時は実績時間に対象となる時間を設定してください。</p>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import moment from "moment";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsOption = {
  NONE: 0,
  ASSIST: 1,
  OTHER: 2
};
const SelectsAbsence = {
  Default: 0,
  Absence: 1
};
export default {
  name: "AttendanceFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  props: {
    kkubun: {
      type: Number,
      default: 1,
      require: true
    }
  },
  computed: {
    Title() {
      const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;
    }
  },
  data() {
    return {
      date: "",
      data: {},
      shozokus: Shozokus,
      shozoku: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      arrayEdit: false,
      recordShifts: [],
      selectWork: SelectsWork.Default,
      SelectsWork,
      selectLateLeave: SelectsLateLeave.Default,
      SelectsLateLeave,
      selectDayCount: SelectsDayCount.Default,
      SelectsDayCount,
      selectNightCount: SelectsNightCount.Default,
      SelectsNightCount,
      selectsOption: SelectsOption.NONE,
      SelectsOption,
      selectAbsence: SelectsAbsence.Default,
      SelectsAbsence,
      forPaid: ""
    };
  },
  methods: {
    shozokuSelect() {
      console.log("selected", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      if (this.edit) {
        this.shifts = filtershifts.filter(e => e.code !== "95000");
      } else {
        this.shifts = filtershifts;
      }
    },
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        console.log(set);
        if (!this.edit) {
          this.data.start = set.option1;
          this.data.end = set.option2;
          this.data.breaktime = set.option3;
        }
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "";
        }
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async checkExistAttendance(payLoad) {
      const isRegistration = await this.$post(
        this.Paths.attendanceCheckRegistration,
        {
          employeeId: payLoad.employeeId,
          affiliationId: payLoad.affiliationId,
          attendanceDate: payLoad.attendanceDate,
          shiftPatternId: payLoad.shiftpatternid
        }
      );

      if (isRegistration) {
        await this.$warning(
          "既に同じシフトの実績が登録されています",
          "勤務実績"
        );
        return true;
      } else {
        return false;
      }
    },
    async onComplete() {
      if (this.shift === "95000") {
        await this.registPublicHoliday();
      } else {
        await this.complete();
      }
    },
    async registPublicHoliday() {
      try {
        if (!this.$refs.form.validate()) return;
        this.$loading();
        const affiliationId = this.shozoku?.code
          ? this.shozoku.code
          : this.shozoku;
        const shift = {
          affiliationId,
          attendanceDate: this.date,
          employeeId: this.data.employee
        };
        await this.$post(this.Paths.registPublicHoliday, shift);
        await this.$info("更新しました。", "勤務実績");
        this.$close(true);
      } catch (e) {
        console.error(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;
      this.$loading();
      const data = { ...this.data };
      const args = this.args;
      Object.keys(args).forEach(key => {
        if (this.data[key]) args[key] = data[key];
      });

      data.date = new Date(this.date).getTime();
      if (this.$isEmpty(data.recordid)) {
        data.datestr = this.date;
        data.affiliation = this.shozoku;
      }
      data.shiftpatternid = this.shift ? this.shift : "";
      if (this.shift !== null && !this.$isEmpty(this.shift.code)) {
        data.shiftpatternid = this.shift.code ? this.shift.code : "";
      } else {
        data.shiftid = "";
      }
      if (data.shiftpatternid === "") {
        this.forPaid = "";
      }
      const setshiftid = [data.shiftid];
      const szk = [""];
      console.log("shozoku", this.shozoku);
      console.log("shozoku.code", this.shozoku.code);
      console.log("shiftpatternid", this.shift);
      if (this.shozoku.code == undefined) {
        szk[0] = this.shozoku;
      } else {
        szk[0] = this.shozoku.code;
      }
      if (data.affiliation != szk[0]) {
        setshiftid[0] = "";
      }
      console.log("forPaid", this.forPaid);

      const payLoad = new AttendanceFix({
        recordId: data.recordid,
        employeeId: data.employee,
        affiliationId: szk[0],
        targetShiftId: setshiftid[0],
        shiftpatternid: data.shiftpatternid,
        attendanceDate: data.datestr,
        startTime: data.start,
        endTime: data.end,
        movingDistance: data.distance,
        breaktime: data.breaktime,
        work: data.work,
        over: data.over,
        midnight: data.midnight,
        midnightover: data.midnightover,
        inover: data.inover,
        leaveTime: data.leaveTime,
        lateTime: data.lateTime,
        lateleave: this.selectLateLeave,
        hadmeal: data.hadmeal,
        workclassificationid: this.forPaid > 0 ? this.forPaid : this.selectWork,
        amount: data.amount,
        reserveAmount1: data.reserveAmount1,
        reserveAmount2: "",
        reserveid1: this.selectDayCount,
        nightShift: this.selectNightCount,
        careShu: "0",
        careKbn: "0",
        timeKbn: "0",
        nightGuard: "0",
        emergencyFlag: "0",
        midnightTel: "0",
        assistFlag: this.selectsOption === SelectsOption.ASSIST ? "1" : "0",
        otherFlag: this.selectsOption === SelectsOption.OTHER ? "1" : "0",
        absence: this.selectAbsence,
        biko: data.biko
      });

      try {
        let result;
        if (this.$isEmpty(data.recordid)) {
          const isExist = await this.checkExistAttendance(payLoad);
          if (isExist) return;
          result = await this.$post(this.Paths.attendanceFix, payLoad);
        } else {
          result = await this.$put(this.Paths.attendanceFix, payLoad);
        }

        if (result) {
          await this.$info("更新しました。", "勤務実績");
          console.log("complete end");
          this.$close(true);
        }
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getShozoku() {
      console.log("shozoku");
      try {
        const result = await this.$get(this.Paths.shozokuFilterAssist);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          if (this.edit) {
            const shozokufirst = result.filter(
              e => e.code === this.data.affiliation
            );
            this.shozoku = shozokufirst[0];
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          this.recordShifts = result;

          if (this.edit && this.data.affiliation) {
            this.shifts = result.filter(
              e =>
                e.refcode === this.data.affiliation ||
                e.refcode === CommonShiftRefCode
            );

            if (this.data.shiftpatternid) {
              let idx = 0;
              let counter = 0;
              this.shifts.forEach(f => {
                if (f.code === this.data.shiftpatternid) idx = counter;
                counter += 1;
              });
              this.shift = this.shifts[idx];
            }
          }
          if (this.edit) {
            this.shifts = this.shifts.filter(e => e.code !== "95000");
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    setRadioValues() {
      // 出勤種別
      if (this.data.workclassificationid === "1") {
        this.selectWork = this.SelectsWork.Break;
      } else {
        this.selectWork = this.SelectsWork.Default;
      }

      if (this.data.workclassificationid) {
        if (parseInt(this.data.workclassificationid) > 90000) {
          this.forPaid = this.data.workclassificationid;
        } else {
          this.forPaid = "";
        }
      }

      // 遅刻早退
      switch (this.data.lateleave) {
        case "遅":
          this.selectLateLeave = this.SelectsLateLeave.Late;
          break;
        case "早":
          this.selectLateLeave = this.SelectsLateLeave.Leave;
          break;
        case "遅早":
          this.selectLateLeave = this.SelectsLateLeave.LateLeave;
          break;
        default:
          this.selectLateLeave = this.SelectsLateLeave.Default;
          break;
      }

      // 出勤日数
      if (this.data.reserveid1 === "2") {
        this.selectDayCount = this.SelectsDayCount.Over;
      } else {
        this.selectDayCount = this.SelectsDayCount.Default;
      }

      // 夜勤日数
      if (this.data.nightshift === "1.0") {
        this.selectNightCount = this.SelectsNightCount.Over;
      } else {
        this.selectNightCount = this.SelectsNightCount.Default;
      }

      // 応援・その他
      if (this.data.assistFlag === "1") {
        this.selectsOption = this.SelectsOption.ASSIST;
      } else if (this.data.otherFlag === "1") {
        this.selectsOption = this.SelectsOption.OTHER;
      } else {
        this.selectsOption = this.SelectsOption.NONE;
      }

      // 欠勤
      if (this.data.reserveid2 === "1") {
        this.selectAbsence = this.SelectsAbsence.Absence;
      } else {
        this.selectAbsence = this.SelectsAbsence.Default;
      }

      console.log(this.data);
    }
  },
  async created() {
    console.log("created", this.args);
    const args = this.args;
    if (!this.$isEmpty(args)) {
      if (this.$isEmpty(args["recordid"])) {
        this.edit = false;
      } else {
        this.edit = true;
      }

      if (!Array.isArray(args)) {
        this.data = { ...args };
      } else {
        this.arrayEdit = true;
        const data = {};
        args.forEach(select => {
          Object.keys(select).forEach(key => {
            if (!data[key]) data[key] = select[key];
            else if (data[key] !== select[key]) data[key] = null;
          });
        });
        this.data = data;
      }
      if (
        this.data.assistFlag !== "1" &&
        this.data.otherFlag !== "1" &&
        this.kkubun !== 4
      ) {
        this.data.amount = 0;
      }

      this.date = moment(this.data.datestr).format("YYYY-MM-DD");

      this.setRadioValues();

      await this.getShozoku();
      await this.getShiftPattern();
    }
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
