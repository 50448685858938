export default class AttendanceOther {
  targetDate: string;
  employeeId: string;
  affiliationId: string;
  amount: number;
  biko: string;
  constructor(
    targetDate = "",
    employeeId = "",
    affiliationId = "",
    amount = 0,
    biko = ""
  ) {
    this.targetDate = targetDate;
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.amount = amount;
    this.biko = biko;
  }
}
