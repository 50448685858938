export default class AttendanceAssist {
  targetDate: string;
  employeeId: string;
  affiliationId: string;
  startTime: string;
  endTime: string;
  amount: number;
  reserveAmount1: number;
  biko: string;
  constructor(
    targetDate = "",
    employeeId = "",
    affiliationId = "",
    startTime = "",
    endTime = "",
    amount = 0,
    reserveAmount1 = 0,
    biko = ""
  ) {
    this.targetDate = targetDate;
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.startTime = startTime;
    this.endTime = endTime;
    this.amount = amount;
    this.reserveAmount1 = reserveAmount1;
    this.biko = biko;
  }
}
